import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import MissionContent from "../../components/team/mission-content";
import SponsorsBlock from "../../components/sponsors-block";
import MyltFaq from "../../components/mylt-faq";

const About = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | About">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="About MYLTF" crumbTitle="About MYLTF" />
      <Container className="about-headertext">
        <Row>
            When I look at these kids on the court I see myself. Kids see me and
            can identify with their doing because I was once in their shoes. Now
            they believe in themselves and I’ve instilled hope. I would like to
            introduce myself: Eric “EJ" Jackson. I am a native of Los Angeles,
            California; I relocated to Atlanta in 1992. I started playing tennis
            when I was four years old. And I was talented, but even with talent,
            I needed constant attention and coaching to lead me on the way. And
            there were several people who helped me to reach my goal. I had
            several wonderful coaches, from Richard Williams, Rick Davison
            (former Georgia Tech coach), Nick Saviano (former USTA national
            coach, and Norman Wilkerson just to name a few, who did everything
            tennis-wise for me: taught me, corrected me, and arranged
            transportation and support when I needed it.  My parents were
            totally in my corner, offering encouragement and open-ended support
            for me as my tennis career grew. Later on, there were people who
            helped me beyond tennis: I had academic support from a college
            professor who helped me score well on the SAT and ACT.
        </Row>
      </Container>
      <MissionContent />
      <MyltFaq />
      <SponsorsBlock />
    </div>
    <Footer />
  </Layout>
);

export default About;
